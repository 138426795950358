import React, { useContext, useState, useEffect } from "react";
import { Animate, Button, Content, Table, useNavigate, ViewContext, ModalCustom, Grid } from "components/lib";
import { getFilterDataPricesUser } from "components/bridges/BridgeServices";
import { getCandidateAgroCsvList, getCandidateById, buscaEmisionSdp, contratoXParametros } from "../../../components/bridges/BridgeServices";
import { BackButton } from "components/backButton/button";
import GlobalStyle from "../../../css/globalStyle.module.scss";
import Input from "../../../css/globalStyle.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Style from "./agricultureSeller.module.scss";
import useViewPort from "components/hooks/useViewPort";
import "../../../css/DateRangePicker.css";

export function PriceAgriculture(props) {
  const context = useContext(ViewContext);
  const [screenSize] = useViewPort();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    number: "",
    id: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
    secuencial: "",
    channel: "",
  });
  const [allCandidates, setAllCandidates] = useState([]);
  JSON.parse(localStorage.getItem('profile'));
  const [open, setOpen] = useState(false);
  const [buscaEmisionSdpObj, setBuscaEmisionSdpObj] = useState(undefined);
  const [modalPdf, setModalPdf] = useState(false);
  const [labelPdf, setLabelPdf] = useState("Descargando el certificado...");
  const [canEdit, setCanEdit] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [textSubtitle, setTextSubtitle] = useState('');

  useEffect(() => {
    const user_permissions = JSON.parse(localStorage.getItem("user_permissions"));
    const agro = user_permissions.agro.candidato;
    setCanEdit(agro.u);//update ermission
  }, [])

  const handleSearchData = async () => {
    let obj = {
      nro_documento: dataSeach.id,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "agro",
      secuencial: dataSeach.secuencial,
      canal: dataSeach.channel,
    };

    await getFilterDataPricesUser(obj).then((res) => {
      let respCandidates = res.data;
      let candidates = [];
      if (res?.data) {
        respCandidates.map(async (item) => {
          if (item.step_two_request !== null) {
            candidates.push(item);
          }
        });
        setAllCandidates(candidates);
      } else {
        context.notification.show("No existen registros", "error", true);
        setAllCandidates([]);
      }
    }).catch((err) => {
      const errMessage = '' + err;
      context.notification.show(errMessage, "error", true);
    });
  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {
    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const alphanumericExtendedRegex = /^[0-9 ]+$/;
    if (value === '' || alphanumericExtendedRegex.test(value)) {
      setDataSearch({ ...dataSeach, [name]: value });
    }
  };

  async function editCandidate(data, callback) {
    context.modal.show(
      {
        title: "Emitir Póliza",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          secuencial: {
            label: "No. de solicitud",
            type: "text",
            required: true,
            readonly: true,
            value: data.secuencial,
          },
          estado: {
            label: "Estado",
            type: "radio",
            options: ["Emitir"],
            required: true,
            errorMessage: "Por favor selecciona una opción",
          },
        },
        buttonText: "Actualizar",
        url: "api/candidate/updateStepAgroEmitir",
        method: "PATCH",
      },
      (res) => {
        handleSearchData();
      }
    );
  }

  async function searchCotizacion(data) {
    setShowLoading(true);
    let responseCandidate = await getCandidateById(data.id);
    let astr_id_proveedor = responseCandidate?.data[0].emitesdp_request?.astr_id_proveedor;
    if (responseCandidate?.data[0].estado === 'No Emitido') {
      let jsonBusca = responseCandidate?.data[0]?.buscaemisionsdp_response;
      setBuscaEmisionSdpObj(jsonBusca ? jsonBusca : {})
      setOpen(true);
    } else {
      setModalPdf(true);
      let responseBusca = await buscaEmisionSdp(astr_id_proveedor, data.id)
      let jsonToParse = responseBusca?.data ? responseBusca?.data?.message : responseBusca?.response?.data?.message;
      let jsonBusca = JSON.parse(jsonToParse);
      //Cuando la api buscaemisionsdp responde OK => descargar PDF con contratoXParametros
      //Sino mostrar pop up con mapeando la respuesta
      if (jsonBusca?.resultado === 'OK') {
        let objContratosXParametros = {
          "agencia": jsonBusca.astr_agencia,
          "ramo": jsonBusca.astr_ramo,
          "poliza": jsonBusca.astr_poliza,
          "cotizacion": "",
          "endoso": jsonBusca.aint_endoso.toString(),
          "certificado": jsonBusca.aint_certificado.toString(),
        }
        try {
          const res = await contratoXParametros(objContratosXParametros);

          // Check if the response indicates success
          if (res?.status === 200 && res?.astr_poliza_firmada) {
            const base64Url = `data:application/pdf;base64,${encodeURIComponent(res.astr_poliza_firmada)}`;
            const link = document.createElement('a');
            link.href = base64Url;
            link.download = 'myPDF.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setModalPdf(false);
            handleSearchData();
            setShowLoading(false);
          } else {
            // Handle non-200 status codes gracefully
            console.error('Unexpected response:', res);
            setLabelPdf("Error al descargar el PDF");
            setShowLoading(false);
            setTextSubtitle(JSON.stringify(res));
          }
        } catch (error) {
          // Handle errors (e.g., network issues or server errors)
          console.error('Error during contratoXParametros execution:', error);
          setLabelPdf("Error al descargar el PDF");
          setShowLoading(false);
        }

      } else {
        setModalPdf(false);
        setBuscaEmisionSdpObj(jsonBusca ? jsonBusca : {})
        setOpen(true);
        setShowLoading(false);
      }
    }
  }

  async function generateCsvList() {
    let secuencial = allCandidates
      .map(function (elem) {
        return elem.secuencial;
      })
      .join("-");
    let respCsv = await getCandidateAgroCsvList(secuencial, 'agro');
    window.location.href = respCsv?.data;
  }

  const makeDataTable = () => {
    let tempDataTable = {
      header: [
        { name: "detail", title: "Detalles", sort: false },
        { name: "actionsPrice", title: "Acciones", sort: false },
        { name: "numberSolicitud", title: "No. de solicitud", sort: false },
        { name: "ci", title: "Cédula", sort: false },
        { name: "fechaIngreso", title: "Fecha ingreso de solicitud", sort: false },
        { name: "estado", title: "Estado", sort: false },
        { name: "cultivo", title: "Cultivo", sort: false },
        { name: "hectareas", title: "Hectáreas", sort: false },
        { name: "montoAsegurado", title: "Suma asegurada", sort: false },
        { name: "primaTotal", title: "Prima total", sort: false },
        { name: "poliza", title: "Póliza", sort: false },
        { name: "observaciones", title: "Observaciones", sort: false },
      ],
      body: [],
    };

    let tempObjectCultivation = {};
    allCandidates.forEach((item, idx) => {
      tempObjectCultivation.detail = item;

      if (item?.estado === "Anulado") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon="eye"
              name="view"
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip="Ver cotización"
            />
          </div>
        );
      } else if (item?.estado === "No Emitido") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon="eye"
              name="view"
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip="Ver cotización"
            />
            <Button
              icon="toggle-left"
              tooltip="Generar póliza"
              name="emit"
              action={() => {
                editCandidate(item);
              }}
            />
            <Button
              icon="search"
              tooltip="Buscar póliza"
              name="search"
              action={() => {
                searchCotizacion(item);
              }}
            />
          </div>
        );
      } else if (item?.estado === "En Proceso" || item?.estado === "Emitido") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon="eye"
              name="view"
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip="Ver cotización"
            />
            <Button
              icon="search"
              tooltip="Buscar póliza"
              name="search"
              action={() => {
                searchCotizacion(item);
              }}
            />
          </div>
        );
      } else {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            {item?.estado !== "Pendiente" && item?.estado !== "Negar" && (
              <Button
                icon="eye"
                name="view"
                action={() => {
                  navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
                }}
                tooltip="Ver cotización"
              />
            )}
            {canEdit && item?.estado !== "Pendiente" && item?.estado !== "Negar" && (
              <Button
                icon="toggle-left"
                tooltip="Generar póliza"
                name="emit"
                action={() => {
                  editCandidate(item);
                }}
              />
            )}
          </div>
        );
      }

      tempObjectCultivation.numberSolicitud = (
        <div>{item.secuencial.toString().padStart(4, "0")}</div>
      );
      tempObjectCultivation.ci = <div>{item.nro_documento}</div>;
      tempObjectCultivation.fechaIngreso = (
        <div>{item.created_at.split("T")[0]}</div>
      );

      if (item?.estado === "Aprobar") {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>Aprobado Manual</div>
        );
      } else if (item?.estado === "Negar") {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>Negado</div>
        );
      } else {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold" }}>{item?.estado.toUpperCase()}</div>
        );
      }

      tempObjectCultivation.cultivo = (
        <div>{item?.step_two_request?.description?.cultivo_Asegurar?.toUpperCase()}</div>
      );
      tempObjectCultivation.hectareas = (
        <div style={{ textAlign: "right" }}>
          {item?.step_two_request === null
            ? ""
            : parseFloat(item?.step_two_request?.description?.superficie)?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </div>
      );
      tempObjectCultivation.montoAsegurado = (
        <div style={{ textAlign: "right" }}>
          {item?.step_two_response?.description?.valorAsegurado?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
      tempObjectCultivation.primaTotal = (
        <div style={{ textAlign: "right" }}>
          {item?.estado === "Negar"
            ? ""
            : item?.step_two_response?.description?.prima?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </div>
      );

      tempObjectCultivation.poliza = (
        <div style={{ textAlign: "right" }}>
          {item?.buscaemisionsdp_response?.astr_poliza}
        </div>
      );
      tempObjectCultivation.observaciones = (
        <div>
          {item?.estado === "Pendiente"
            ? item?.step_two_response?.description?.observaciones
            : item?.rules?.observaciones}
        </div>
      );

      tempDataTable.body.push(tempObjectCultivation);
      tempObjectCultivation = {};
    });

    setData(tempDataTable);
  };

  useEffect(() => {
    makeDataTable();
  }, [allCandidates]);

  const closeModalLight = () => {
    setOpen(false);
  };

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton action={() => navigate("/vendedor/agricola")} />
      </div>
      <section className={GlobalStyle.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={GlobalStyle.textTitle}>Agrícola</p>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <div className={GlobalStyle.priceInputSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de solicitud:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.secuencial}
                  name='secuencial'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Canal:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.channel}
                  name='channel'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de documento:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.id}
                  name='id'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Fecha ingreso de solicitud:</label>
                <DateRangePicker
                  onChange={handleDate}
                  value={dataSeach.date}
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button color='blue' text='Buscar' action={handleSearchData} />
              </div>
            </div>
          </div>
          <div>
            <Scrollbars style={{ width: "100%", height: "600px" }}>
              <Table
                id='table-Cotizaciones'
                show={[
                  "actionsPrice",
                  "numberSolicitud",
                  "ci",
                  "fechaIngreso",
                  "estado",
                  "cultivo",
                  "hectareas",
                  "montoAsegurado",
                  "primaTotal",
                  "poliza",
                  "observaciones",
                ]}
                data={data}
                loading={Object.entries(data).length === 0 ? true : false}
              // actions={{ edit: viewProduct }}
              />
            </Scrollbars>
          </div>
          <div style={{ padding: "20px 0px", textAlign: "right" }}>
            {allCandidates.length > 0 ? (
              <Button
                color='blue'
                text='Descargar Tabla'
                action={generateCsvList}
              />
            ) : (
              <p></p>
            )}
          </div>
          {/* Verificar Emisión Modal */}
          <ModalCustom
            display={
              <div>
                <div style={{ textAlign: 'center' }}>
                  <p className={GlobalStyle.textTitle}>Verificar Emisión</p>
                </div>
                <Grid>
                  <p><b>resultado:</b> {buscaEmisionSdpObj?.resultado}</p>
                  <p><b>astr_agencia:</b> {buscaEmisionSdpObj?.astr_agencia}</p>
                  <p><b>astr_ramo:</b> {buscaEmisionSdpObj?.astr_ramo}</p>
                  <p><b>astr_poliza:</b> {buscaEmisionSdpObj?.astr_poliza}</p>
                  <p><b>aint_certificado:</b> {buscaEmisionSdpObj?.aint_certificado}</p>
                  <p><b>aint_endoso:</b> {buscaEmisionSdpObj?.aint_endoso}</p>
                </Grid>
              </div>
            }
            width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
            height='auto'
            open={open}
            closeModal={closeModalLight}
          />
          {/* Download Modal */}
          <ModalCustom
            display={
              <div>
                <div style={{ textAlign: 'center' }}>
                  <p className={GlobalStyle.textTitle}>{`${labelPdf}`}</p>
                </div>
                {showLoading ?
                  <div className={Style.ldsDualRingContainer}>
                    <div className={Style.ldsDualRing}></div>
                  </div> :
                  <div>
                    <p className={GlobalStyle.textSubTitle}>{`API Response: ${textSubtitle}`}</p>
                  </div>
                }
              </div>
            }
            width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
            height='auto'
            open={modalPdf}
            closeModal={() => setModalPdf(false)}
          />

        </Content>
      </section>
    </Animate>
  );
}
